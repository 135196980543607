.pdf_card{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 270px;
}
.pdf_image img{
    width: 30px;
}
.pdf_each_card{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pdf_each_card p {
    margin: 0;
}
.three_dot_icon{
    position: relative;
    border: 1px solid;
    padding: 8px 8px;
}
.action_icons{
    position: absolute;
    padding: 10px;
    right: 0px;
    z-index: 2;
    background: #0000ff0d;
    border: 2px solid #0000ff96;
    border-radius: 5px;
}
.action_type{
    display: flex;
    gap: 3px;
    align-items: center;
    border-bottom:1px solid ;
    padding:  5px;
}
.pdf_design{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.folder_year{
    margin: 0;
    padding: 2px;
}
.folder_icon{
    width: 35px;
}