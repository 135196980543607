  
  .invoice {
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(160deg, #0056b3, #1b70b7);
    background-size: 400% 400%;
    padding: 10px;
    -webkit-animation: AnimationName 34s ease infinite;
    -moz-animation: AnimationName 34s ease infinite;
    animation: AnimationName 34s ease infinite;
  }
  
  .container {
    width: 100%;
    max-width: 30rem;
    background-color: #fff;
  }
  
  .heading {
    text-align: center;
    font-size: 1rem;
    color: #fff;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 40px;
  }
  
  .form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1rem;
  }
  
  .form-heading {
    font-size: 1.5rem;
  }
  .input-box {
    display: block;
  }
  .input-box input {
    width: 90%;
    height: 30px;
    margin: 10px 0px;
  }
  .input-box select {
    width: 90%;
    height: 30px;
    margin: 10px 0px;
  }

  .btn {
    width: 70%;
    margin: 0 auto;
  }
  .btn button{
    width: 100%;
    padding: 0.8rem 0;
    color: #fff;
    font-weight: 600;
    background-color: #0056b3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .btn button:hover {
    background-color: #7eb2dd;
  }
  
  
  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 15%;
    }
    50% {
      background-position: 100% 86%;
    }
    100% {
      background-position: 0% 15%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 15%;
    }
    50% {
      background-position: 100% 86%;
    }
    100% {
      background-position: 0% 15%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 15%;
    }
    50% {
      background-position: 100% 86%;
    }
    100% {
      background-position: 0% 15%;
    }
  }
  .invoice_detail_close{
    background-color: green;
    padding: 7px 20px;
    border: none;
    font-weight: 600;
    color: #fff;
  border-radius: 5px;
  }