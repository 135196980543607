.customer_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}
.customer_heading button {
    background-color: #0056b3;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    color: #fff;
}
.add_customer_modal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background-color: #cf1f1f;
    padding: 5px 10px;
}
.add_customer_modal h3{
    margin: 0;
}
.add_customer_modal button{
    background-color: transparent;
    padding: 5px 20px;
    border: none;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
}
.customer_card{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top:10px;
    align-items: start;
}
.address_details{
    display: block;
    /* width: 220px; */
    text-transform: uppercase;
}
.customer_title{
    font-weight: 600;
}
.customer_card_data{
    padding: 15px;
    border-radius: 5px;
    background-color: #01572E;
    color: #fff;
    position: relative;
    width: 100%;
    margin-top: 10px;
}
.customer_card_data div{
    line-height: 23px;
}
.edit_button{
  position: absolute;
  right: 7px;
  top: 7px;
}
.forms {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1rem;
    background-color: #fff  ;
  }
  
  .form-headings {
    font-size: 1.5rem;
  }
  .input-boxs {
    display: block;
  }
  .input-boxs input {
    width: 90%;
    height: 35px;
    margin: 10px 0px;
  }
  .input-boxs textarea {
    display: block;
    width: 90%;
    height: 100px;
    margin: 10px 0px;
    resize: none;
  }
  .add_btn{
    text-align: center;
  }
  .forms button {
    background-color: #0056b3;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
}